#Footer {
  padding-top: 140pt;
  background-image: url("../../assets/images/footerBg.png");
  background-size: cover;
}

#FooterWhite {
  padding-top: 140pt;
  background-image: url("../../assets/images/footerBgWhite.png");
  background-size: cover;
}

#hr {
  border: 0;
  border-top: 1.1px solid;
  opacity: 1 !important;
  margin-top: 16px !important;
}

#hr-white-footer {
  border: 0;
  border-top: 1.1px solid black;
  opacity: 1 !important;
  margin-top: 16px !important;
}

.footer-icon {
  margin-right: 8pt;
  color: white;
}

.footer-icon-mb {
  margin-right: 6pt;
  color: white;
}

.footer-icon:hover {
  color: var(--main-color);
}

.footer-ul {
  list-style: none;
}

.footer-ul li {
  font-weight: 200;
  cursor: pointer;
  margin-bottom: 5px;
}

.newsletter {
  padding-top: 5pt;
  padding-bottom: 5pt;
  padding-left: 5pt;
  width: 100%;
  background: transparent;
  border: 1px solid white;
  color: white;
}

@media (max-width: 480px) {
  #Footer {
    padding-top: 60pt !important;
    margin-top: 35pt !important;
    background-image: url("../../assets/images/footerBgMb2.png") !important;
  }
  #FooterWhite {
    background-image: url("../../assets/images/footerBgMbWhite.png");
    padding-top: 60pt !important;
  }

  #FooterLogo {
    height: 30pt !important;
    margin-bottom: 15pt;
  }
  #footerLogoSection {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 10pt;
  }

  #footerLogoSection div {
    gap: 30pt !important;
  }
  #footerLogoSection div a {
    margin: 0px !important;
  }
  #footerListSection {
    text-align: center !important;
  }
  #footerListSection ul {
    padding-left: 0px !important;
  }
  #NewsLetterTitle {
    margin-bottom: 20pt;
    text-align: center !important;
  }
  #NewsLetterCol {
    display: block !important;
  }
  #NewsLetterCol div {
    text-align: center !important;
  }
  #NewsLetterCol button {
    margin-top: 10pt !important;
    width: 90% !important;
    margin-bottom: 10pt !important;
  }
  #PrivacyConditions {
    justify-content: center !important;
  }
  .footer-icon {
    margin-right: 13pt !important;
  }
  #footerLogoSection div {
    width: 100% !important;
  }
  #footerCol {
    margin-bottom: 8pt !important;
  }
  #hr {
    margin-top: 8px !important;
    margin-bottom: 12px !important;

    border: 0;
    border-top: 0.1px solid !important;
    opacity: 1 !important;
    margin-top: 16px !important;
  }

  #hr-white-footer {
    margin-top: 8px !important;
    margin-bottom: 12px !important;
  }
}
