@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
body {
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}

:root {
  --main-color: linear-gradient(180deg, #004c3f 0%, #002419 100%);
  --main-color-2: rgba(0, 76, 63, 97%);
  --main-bg: linear-gradient(
    90deg,
    #002419 0%,
    #00382c 20.31%,
    #004c3f 50.12%,
    #003b2e 79.69%,
    #002419 100%
  );
}

.font-200 {
  font-weight: 200;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
