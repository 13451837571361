.navbar-nav a {
  color: black;
  font-size: 14px;
  text-transform: uppercase;
}

.navBar {
  border: 0.1px solid rgba(204, 204, 204, 0.2) !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.container {
  max-width: 90vw !important;
}

.container-md {
  max-width: 90vw !important;
}

#offcanvasNavbar-expand-md {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.show-more-products:hover {
  text-decoration: underline !important;
}
/* .container-md {
  margin-right: 20px !important;
  margin-left: 20px !important;
} */

.nav-link {
  margin-left: 15px;
}

.navbar-brand {
  font-weight: 500;
  font-size: 1.4rem;
}

.nav-bar-contactus {
  border-radius: 50px;
  color: white !important;
  width: 100pt;
  background: var(--main-color);
  padding-top: 6pt !important;
  padding-bottom: 6pt !important;
  display: flex !important;
  justify-content: center !important;
  font-size: 14px !important;
  border: 1px solid var(--main-color-2) !important;
}

.nav-bar-contactus:hover {
  background: transparent !important;
  border: 1px solid var(--main-color-2) !important;
  color: var(--main-color-2) !important;
}

.about-us-section {
  background-color: #f8f9fa;
  border-radius: 20px;
}

.link-text {
  color: black;
  text-decoration: none;
}
/* .hero-right img {
  padding-left: 10pt;
} */

.link-text:hover {
  color: black;
  text-decoration: none;
  opacity: 0.9;
}

.main-bg {
  background: var(--main-bg);
}

.md-container {
  padding: 0pt 90pt 0pt 90pt;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.slick-slide .card-wrapper {
  margin: 10pt;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  background: linear-gradient(180deg, #38786a 0%, #002419 100%);
  padding-top: 8pt;
  padding-left: 10pt;
  padding-right: 10pt;
  border-radius: 5pt;
  text-align: center;
  padding-bottom: 20pt;
  color: white;
  height: 270pt;
  width: 95%;
}

.slick-slide .card-wrapper img {
  height: 50% !important;
  width: 100%;
}

.navBar .animated-border {
  position: relative;
}

.navBar .animated-border::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: var(--main-color-2);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.navBar .animated-border:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.activeNav::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: var(--main-color-2);
  bottom: 0;
  left: 0;
}

.react-tel-input input {
  width: 100% !important;
  height: 37px !important;
}

.domain-list {
  list-style: none;
  padding-left: 5pt;
}

.domain-list li {
  margin-bottom: 6pt;
}

.tick-icon {
  margin-right: 15pt !important;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.solution-list {
  list-style: none;
  padding-left: 2px;
}

.solution-list li {
  font-size: 14px;
}

.small-box {
  background-color: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  border: 1px solid #ffffff28;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  padding: 3% 14% 3% 7%;
}

.small-box2 {
  background-color: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  border: 1px solid #ffffff28;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  padding: 3% 44% 3% 4%;
}

.OurSolutionsPage {
  padding-top: 80pt;
}

#Starter {
  margin-top: 125pt;
}

.form-control {
  border-color: var(--main-color-2) !important;
}

#newsLetterBg {
  background-image: url("../assets/images/newsLetterBg.png");
  background-size: cover;
}

#ourstoriesBg {
  background-image: url("../assets/images/blogsBg.png");
  background-size: cover;
  min-height: 100vh;
}
#dropdown-basic {
  width: 73pt !important;
  border: 1.53px solid rgba(176, 176, 176, 1);
  background-color: rgba(246, 246, 246, 1) !important;
  color: black;
  text-align: left;
  font-size: 10pt;

  background: linear-gradient(180deg, #f7f7f7 0%, #eeeeee 70.83%, #b5b5b5 100%);
  border: 1.53125px solid #b0b0b0;
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle::after {
  width: 7pt;
}

.productCard {
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  background: linear-gradient(180deg, #38786a 0%, #002419 100%);
  padding-top: 8pt;
  padding-left: 10pt;
  padding-right: 10pt;
  border-radius: 5pt;
  padding-bottom: 20pt;
  color: white;
  height: 250pt;
  width: 21vw;
}

.productCard img {
  height: 50% !important;
  width: 100%;
}

.blogpageloader {
  margin-top: 30%;
  margin-bottom: 30%;
}

.productCard:hover {
  background: rgba(56, 120, 106, 1) !important;
}

.productCard:hover .read-more {
  text-decoration: underline !important;
}

.our-product-small-txt:hover {
  text-decoration: underline;
  cursor: pointer;
}

.article-bg {
  width: 80%;
  background: rgba(72, 72, 72, 0.05);
  backdrop-filter: blur(45px);
  border-radius: 6.33px;
}

.Product-Search {
  box-sizing: border-box;
  width: 263pt;
  color: #002419;
  height: 35px;
  padding-left: 35px;
  border: 1.1px solid #002419;
  border-radius: 46px 0px 0px 46px;
  border-right: none;
}

.Product-Search-mb {
  box-sizing: border-box;
  width: 100%;
  color: #002419;
  height: 35px;
  padding-left: 35px;
  border: 1.1px solid #002419;
  border-radius: 46px;
}

.Product-Search:focus {
  outline: none;
}

@media (max-width: 480px) {
  .slick-slide .card-wrapper {
    margin: 0px !important;
    margin-right: 1.5px !important;
    margin-left: 1.5px !important;
  }
  .mobile-icon {
    height: 40px !important;
  }
  .mobile-h4 {
    font-size: 18px !important;
  }
  .mobile-p {
    font-size: 14px !important;
  }
  #ourDomainCol {
    margin-bottom: 15pt !important;
  }
  #about-us-sec {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    margin-bottom: 70px !important;
  }
  #our-domain-section {
    margin-bottom: 15pt !important;
  }
  #transparent-card {
    padding-bottom: 0px !important;
  }
  #transparent-card-title {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  #our-solution-section {
    padding-bottom: 0rem !important;
  }
  .newsletter-h1 {
    font-size: 24px !important;
  }

  .productCard {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #38786a 0%, #002419 100%);
    padding-top: 8pt;
    padding-left: 10pt;
    padding-right: 10pt;
    border-radius: 5pt;
    padding-bottom: 20pt;
    color: white;
    height: 155pt;
    width: 45vw;
  }

  #prd-card-title {
    height: 20pt;
    margin: 5pt 0pt;
  }

  #our-prd-main h6 {
    font-size: 8pt !important;
    padding-bottom: 1pt !important;
  }

  #our-prd-main p {
    font-size: 7pt !important;
  }

  #our-prd-main #ourDomainRow {
    margin-left: 0 !important;
    width: 100vw !important;
    display: flex;
    justify-content: space-between;
    padding-left: 10pt !important;
    padding-right: 10pt !important;
  }

  #our-prd-main #allprjs {
    margin-right: 0pt !important;
    padding: 0 !important;
  }

  .productCard img {
    height: 60pt !important;
    width: 100%;
  }

  .productCard:hover {
    background: rgba(56, 120, 106, 1) !important;
  }

  .productCard:hover .read-more {
    text-decoration: underline !important;
  }

  #transparent-card img {
    height: 80pt !important;
  }
  .mobile-title {
    font-size: 18px;
  }
  .navbar-brand {
    margin-left: 0px !important;
  }
  .hero-button {
    margin-top: 20px;
  }
  #prj-slider {
    width: 85% !important;
  }
  #prj-sec {
    margin-bottom: 0pt !important;
    padding-bottom: 1rem !important;
  }
  #newsLetterBg {
    background-image: url("../assets/images/newsLetterBgMb.png") !important;
  }
  #Starter {
    margin-top: 90pt;
  }
  #ourstoriesBg {
    background-image: url("../assets/images/blogsBgMb.png");
    background-size: cover;
    height: 225vh;
  }

  .aboutus-text {
    width: 90% !important;
  }
  .OurSolutionsPage {
    padding-top: 40pt !important;
  }

  .hero-right {
    margin-top: 20pt !important;
  }

  .md-container {
    padding: 0pt 20pt 0pt 20pt;
  }

  #navnav {
    padding-left: 0pt !important;
    padding-right: 0pt !important;
    justify-content: space-between !important;
  }
  .navitems {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  .navitems a {
    margin-top: 5px;
  }
  #hero-section {
    margin-bottom: 20pt !important;
    text-align: center;
  }
  .hero-left {
    display: flex !important;
  }
  #ourSolutionCol {
    margin-bottom: 20pt;
  }
  #ourSolutionRow {
    width: 90% !important;
  }
  #ourDomainDesc {
    padding-left: 10pt !important;
    padding-right: 10pt !important;
  }
  #ourDomainCol {
    margin-bottom: 20pt;
  }
  #ourTopStoriesCol {
    text-align: center;
    margin-bottom: 20pt !important;
  }
  #ourTopStoriesCol div {
    text-align: center !important;
    margin-top: 0px !important;
    margin-bottom: 30pt;
  }

  .ourTopStoriesCol2A {
    padding-bottom: 30pt;
  }

  #ourTopStoriesCol2 {
    display: flex;
    justify-content: center;
  }
  #ourTopStoriesCol2 div {
    display: block !important;
  }
  #aboutUsCol div {
    display: block !important;
  }
  #aboutUsCol div p {
    width: 100% !important;
    text-align: center !important;
    padding-top: 0px !important;
  }
  #aboutUsImg {
    height: 100% !important;
    width: 100% !important;
    margin-top: 10pt;
  }
  #FormText {
    text-align: center !important;
    margin-bottom: 5pt;
  }
  .hero-right {
    margin-top: 30pt;
  }
  .hero-right img {
    height: 160pt !important;
    padding-left: 0pt !important;
  }
  #newsLetterInput {
    width: 100% !important;
  }
  #newsLetterInput input {
    width: 92% !important;
  }
  #newsLetterBg {
    margin-bottom: 30px !important;
  }
  #newsLetterCol {
    display: block !important;
  }
  #newsLetterCol div {
    text-align: center !important;
  }
  #newsLetterCol button {
    margin-top: 10pt !important;
    width: 70% !important;
    margin-bottom: 10pt !important;
  }
  .contactUsP {
    padding: 0 !important;
  }
  #ContactUsRow {
    margin-top: 0pt !important;
    margin-bottom: 30pt !important;
  }
  #ContactUsRow {
    width: 100% !important;
  }
  #contact-us-button {
    margin-top: 30pt !important;
    justify-content: center !important;
  }
  .contact-us-spc4r {
    margin-bottom: 1rem !important;
  }
  #contactus-cc4w {
    display: block !important;
  }
  #contactus-cc4w form {
    width: 100% !important;
  }
  #NewsLetterButton {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    margin-top: 5pt;
  }
  #NewsLetterButton button {
    width: 30% !important;
    font-weight: 500;
  }
  #WebLogo {
    height: 22pt !important;
  }
  .d1Image {
    height: 150pt !important;
  }
  .d2Image {
    height: 140pt !important;
  }
  .d3Image {
    height: 130pt !important;
  }
  .d4Image {
    height: 130pt !important;
  }
  #OurDomainContainer {
    text-align: center;
  }
  #ourDomainRow {
    padding-left: 15pt !important;
    padding-right: 15pt !important;
  }
  .domain-desc {
    padding-right: 0px !important;
  }
  .domain-list {
    text-align: left !important;
    padding-left: 3.5px !important;
  }
  #DomainImage {
    display: block !important;
  }
  #ourDomainRow {
    margin-bottom: 60pt !important;
  }
  .retail-icon {
    margin-left: 0px !important;
  }
  #SmallBoxes {
    display: block !important;
  }
  #SmallBoxes .small-box {
    width: 100% !important;
    justify-content: space-around;
  }
  #SmallBoxes > .small-box:first-child {
    margin-bottom: 5pt !important;
  }
  #SolutionRow {
    margin-bottom: 50pt !important;
  }
  #SolutionHeading {
    margin-top: 30pt !important;
    margin-bottom: 30pt !important;
  }

  .SolutionBigCardClass1 {
    height: 320pt !important;
  }

  .SolutionBigCardClass2 {
    height: 420pt !important;
  }

  .SolutionBigCardClass3 {
    height: 300pt !important;
  }

  .ourPrCol {
    margin-top: 20pt;
    margin-bottom: 20pt;
  }
  #our-domain-main {
    margin-top: 100pt !important;
  }
  .domain-list li {
    margin-bottom: 2pt !important;
  }
  .navbar-toggler {
    padding: 0 !important;
  }

  #ourDomainRow {
    padding: 0 !important;
  }

  #product-wrapper {
    padding: 0 !important;
  }
  #product-second-line {
    margin-top: 0 !important;
  }
  #allprjs {
    margin-top: 1pt !important;
  }

  #prd-img {
    width: 100% !important;
    height: 160px !important;
  }
  .navbar-toggler {
    border: none !important;
  }
  #SolutionRow #SolutionRow-col {
    padding-left: 16pt !important;
    padding-right: 16pt !important;
  }
  .sol-row {
    margin-bottom: 50pt !important;
  }
  .sol-row p {
    margin-bottom: 1rem !important;
  }
  #SmallBoxes {
    padding-left: 7pt !important;
    padding-right: 7pt !important;
  }
  #sm-bx-2 {
    padding-left: 7pt !important;
    padding-right: 7pt !important;
  }
  .small-box2 {
    padding: 3% 14% 8% 7% !important;
  }
  #allprjs {
    padding-left: 20pt !important;
    padding-right: 20pt !important;
    margin-bottom: 5pt !important;
  }
  #our-prd-title {
    margin-bottom: 0pt !important;
  }
  #our-prd-main {
    margin-top: 85pt !important;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  #SolutionHeading {
    padding-top: 40pt !important;
  }
  .canvas-icon svg {
    color: #002419 !important;
  }
  .navitems a {
    margin-left: 0 !important;
  }
  #hero-section h1 {
    font-size: 12pt !important;
  }
  .slick-slide .card-wrapper {
    width: 98% !important;
  }
  /* #cards {
    margin-left: 2pt;
    margin-right: 2pt;
  } */
  #hero-section {
    margin-bottom: 20pt !important;
    text-align: center;
    margin-top: 20pt;
  }
  .navbar-toggler-icon {
    background-image: none !important;
    background-color: #004c3f;
    height: 3.5px !important;
    border-radius: 50px !important;
    /* width: 25px; */
    /* margin: 10px 0; */
    /* position: relative; */
    /* transition: all 0.35s ease-out; */
    /* transform-origin: center; */
  }

  .navbar-toggler-icon::before {
    display: block;
    background-color: #004c3f;
    height: 3.5px;
    content: "";
    border-radius: 50px !important;
    position: relative;
    top: -8px;
    transition: all 0.15s ease-out; /*taken down to hide quicker*/
    transform-origin: center;
  }

  .navbar-toggler-icon::after {
    display: block;
    background-color: #004c3f;
    height: 3.5px;
    content: "";
    border-radius: 50px !important;
    position: relative;
    top: 4px;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }

  #go-to-top {
    font-size: 7pt !important;
  }

  .blogpageloader {
    margin-top: 30vh;
    margin-bottom: 30vh;
  }
}

@media (max-width: 991px) {
  #TransparentDGLogo {
    display: none !important;
  }
  #navnav {
    justify-content: space-between !important;
  }
}
